<template>
  <viewcard--c
    :title="titleName"
    :btsave="null"
    :btdelete="null"
    :btback="null"
    :busy="loading"
  >
    <hr class="p-0 m-0 mb-1" />
    <b-overlay
      :variant="skin"
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <b-form @submit.prevent>
        <b-row class="d-flex align-items-center">
          <b-col cols="3">
            <b-form-group label="Empresa">
              <v-select
                v-model="companySelected"
                :options="companies"
                :clearable="true"
                autocomplete="off"
              ></v-select>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Número da questão">
              <b-form-input v-model="record.correct_id" />
            </b-form-group>
          </b-col>
          <b-col cols="1" class="mt-1">
            <b-button
              variant="info"
              @click="getRecord()"
              :id="`question-seach`"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) in questions"
          :key="index"
          class="media-list"
          align-v="center"
        >
          <b-col cols="6">
            <b-media vertical-align="center" class="text-left">
              <template #aside>
                <b-form-group>
                  <b-form-radio-group
                    v-model="record.video_selected"
                    id="radio-group-1"
                    name="radio-options"
                  >
                    <b-form-radio
                      name="item.media_id"
                      :id="item.media_id.toString()"
                      :value="item.media_id.toString()"
                    ></b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </template>
              <div v-if="item.url_video">
                <video :src="item.url_video" width="100%" controls />
              </div>
            </b-media>
          </b-col>
          <b-col cols="6">
            <div class="info-content">
              <div class="tooltips-itens">
                Empresa:
                <b> {{ item.company }} </b>
              </div>
              <div class="tooltips-itens">
                Data do envio para o Upload:
                <b> {{ item.created_at_formatted }} </b>
              </div>
              <div class="tooltips-itens">
                Disciplina:
                <b> {{ item.discipline }} </b>
              </div>
              <div class="tooltips-itens">
                Projeto:
                <b> {{ item.project }} </b>
              </div>
              <div class="tooltips-itens">
                Professor:
                <b> {{ item.teacher }} </b>
              </div>
              <div class="tooltips-itens" v-if="item.appointment_id">
                ID do Agendamento:
                <b>
                  <b-link
                    target="_blank"
                    class="underline"
                    :to="`/pedagogic/calendar?q=${item.appointment_id}`"
                    >#{{ item.appointment_id }}</b-link
                  >
                </b>
              </div>
              <div class="tooltips-itens">
                ID da Gravação:
                <b>
                  <b-link
                    target="_blank"
                    class="underline"
                    :to="`/audiovisual/recording/view/${item.record_media}/${item.subject_id}`"
                    >#{{ item.record_media }}</b-link
                  >
                </b>
              </div>
              <div class="tooltips-itens">
                ID do Assunto:
                <b> {{ item.subject_id }} </b>
              </div>
              <div class="tooltips-itens" v-if="item.institution">
                Instituição:
                <b> {{ item.institution }} </b>
              </div>
              <div class="tooltips-itens" v-if="item.question_year">
                Ano da Questão:
                <b> {{ item.question_year }} </b>
              </div>
              <div class="tooltips-itens">
                Questão informada pelo operador:
                <b>
                  {{ item.external_id ? "SIM" : "NÃO" }}
                </b>
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center" v-if="record.video_selected">
          <b-col cols="3">
            <b-form-group label="Novo número da questão para o vídeo">
              <b-form-input v-model="record.new_id_question" />
            </b-form-group>
          </b-col>
          <b-col cols="1" class="mt-1">
            <b-button
              variant="info"
              @click="confirmReplace()"
              :id="`question-seach`"
            >
              Substituir
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </viewcard--c>
</template>
<script>
import _service from "@/services/upload-question-control";
import useAppConfig from "@core/app-config/useAppConfig";
import {
  BMedia,
  BImg,
  BCardText,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BEmbed,
  BLink,
} from "bootstrap-vue";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BCardText,
    BMedia,
    BImg,
    BFormRadioGroup,
    BFormRadio,
    BFormGroup,
    BEmbed,
    BLink,
  },
  data() {
    return {
      loading: false,
      titleName: "Substituir Questão do Vídeo",
      companies: [],
      questions: [],
      companySelected: null,
      record: {
        correct_id: null,
        video_selected: null,
        media_id: 0,
        video_name: "",
        video_text: "",
        company_id: 0,
        question_control_id: 0,
        urlVideo: "",
        url_question_video_platform: "",
        external_id: 0,
        new_id_question: "",
      },
    };
  },
  async created() {
    await this.getCompanies();
  },
  methods: {
    getRecord() {
      this.record.video_selected = null;
      this.record.new_id_question = null;
      this.questions = [];
      if (this.record.correct_id > 0 && this.companySelected) {
        this.loading = true;
        _service
          .getQuestionReplace(
            this.companySelected.value,
            this.record.correct_id
          )
          .then((res) => {
            if (res && res.content && res.content.length > 0) {
              this.questions = res.content;
              
            } else {
              this.$utils.toastWarning(
                "ID da Questão não localizado",
                "Verifique o número informado"
              );
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));
      } else {
        this.$utils.toastError(
          "Notificação",
          "Informe uma empresa e uma questão para continuar."
        );
      }
    },
    confirmReplace() {
      if (this.record.new_id_question) {
        let textSwal = "Você clicou em 'Substituir' o ID da questão.";
        this.$swal({
          title: textSwal,
          text: "Deseja continuar ?",
          icon: "error",
          showCancelButton: true,
          confirmButtonText: "Sim, quero continuar!",
          cancelButtonText: "Cancelar",
          customClass: {
            confirmButton: "btn btn-info",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.replace_id_question();
          } else {
            this.record.new_id_question = null;
          }
        });
      } else {
        this.$utils.toastError("Notificação", "Informe o novo ID da questão.");
      }
    },
    replace_id_question() {
      const _replaceQuestion = _service.replaceIDQuestion(
        this.companySelected.value,
        this.record.new_id_question,
        this.record.video_selected
      );

      this.loading = true;
      _replaceQuestion
        .then(() => {
          this.$utils.toast("Notificação", "Atualizado com sucesso.");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    async getCompanies() {
      await _service.getTabCompany().then((res) => {
        if (res.content && res.content.length > 0) {
          this.companies = res.content.map((m) => {
            return {
              value: m.id,
              label: m.name,
              slug: m.slug,
            };
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.tooltip-inner {
  max-width: 500px !important;
  text-align: left !important;
}
</style>

<style scoped>
.info-content {
  padding: 10px;
  border: 2px dashed #dddddd;
}
.tooltips-itens {
  margin: 2px;
}
</style>