import HttpService from './HttpService'

const _http = new HttpService()
export default {
  show: (page, company_id, search = null, date_created = null) => {
    let _parans = `?size=20&page=${page - 1}&asc`;
    _parans += `&data.company_params_id=${company_id}`;
    if (search) {
      _parans += `&search=${search}`;
    }
    if (date_created) {
      let separate_date = date_created.split("T")[0];
      let dates = separate_date.split("-");
      date_created = `${dates[2]}-${dates[1]}-${dates[0]}`;
      _parans += `&data.date_created=${date_created}`;
    }
    return _http.get(`/api/upload/question-control${_parans}`)
  },
  getTabCompany: () => {
    return _http.get(`/api/upload/manual-question/get-tab-company`)
  },
  getVersionControl: (media_id) => {
    return _http.get(`/api/upload/question-control/get-control-version/${media_id}`)
  },
  replaceVideo: (payload) => {
    return _http.put('/api/upload/question-control/control-version-replace', payload)
  },
  getQuestionReplace: (company_id, correct_id) => {
    return _http.get(`/api/upload/question-control/get-question-replace/${company_id}/${correct_id}`)
  },
  replaceIDQuestion: (company_id, correct_id, media_id) => {
    return _http.get(`/api/upload/question-control/question-replace/${company_id}/${correct_id}/${media_id}`)
  },
}